import classNames from "classnames";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { getProjectIdFromUrl } from "../api";
import { useAppSelector } from "../hooks";
import { Quote } from "../types/goldpan";
import { interviewUrl } from "../urls";
import { assertNonNull, getSentimentClassnames } from "../utils";
import ThemeTree from "./theme";

const QuoteDisplay = ({
    quote,
    transcriptLink,
}: {
    quote: Quote;
    transcriptLink?: boolean;
}) => {
    const projectId = getProjectIdFromUrl();
    const subthemes = useAppSelector((state) => state.project.subthemes);
    const competitors = useAppSelector((state) => state.project.competitors);
    const interviews = useAppSelector((state) => state.project.interviews);
    const colors = getSentimentClassnames(quote.sentiment);

    const subtheme = useMemo(
        () =>
            quote.subtheme
                ? subthemes.find((subtheme) => subtheme.id === quote.subtheme)
                : undefined,
        [quote, subthemes],
    );
    const competitor = useMemo(
        () =>
            quote.competitor
                ? competitors.find(
                      (competitor) => competitor.id === quote.competitor,
                  )
                : undefined,
        [quote, competitors],
    );
    const interview = assertNonNull(
        interviews.find(({ id }) => id === quote.interview),
    );

    return (
        <div className="flex flex-col bg-white rounded w-full my-2 p-6 border-gray-100 border-b shadow">
            <div
                className={classNames(
                    "whitespace-pre-line border-l-4 p-4 text-lg font-medium",
                    colors.background,
                    colors.border,
                )}
            >
                {quote.text}
            </div>
            <div className="border-b border-gray-200 mt-6 mb-4"></div>
            <div className="text-sm">
                <div className="flex items-center gap-2 justify-between w-full text-xs">
                    <div className="flex items-center divide-x gap-4">
                        {subtheme && <ThemeTree subtheme={subtheme} />}

                        {competitor && (
                            <div className={classNames({ "pl-4": !!subtheme })}>
                                <p className="text-xs text-gray-500">
                                    Competitor
                                </p>
                                <p className="font-semibold text-xs">
                                    {competitor.name}
                                </p>
                            </div>
                        )}
                    </div>

                    {transcriptLink && quote.interview_paragraph ? (
                        <button
                            className="text-white bg-gp-blue hover:bg-gp-blue-light rounded-md p-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                            role="button"
                            onClick={() => {
                                [].forEach.call(
                                    document.querySelectorAll(
                                        ".interview-paragraph.highlight",
                                    ),
                                    function (el: HTMLElement) {
                                        el.classList.remove("highlight");
                                    },
                                );
                                const paragraph = document.getElementById(
                                    "paragraph_" + quote.interview_paragraph,
                                );
                                paragraph?.classList.add("highlight");
                                paragraph?.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }}
                        >
                            See in transcript
                        </button>
                    ) : (
                        <Link
                            className="button primary justify-self-end"
                            to={interviewUrl(projectId, interview.slug)}
                        >
                            See interview
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuoteDisplay;
