import { useMemo } from "react";
import { useParams } from "react-router-dom";

import DropdownMenu from "../components/dropdown-menu";
import FilterSidebar from "../components/filters/filter-sidebar";
import Loader from "../components/loader";
import QuoteDisplay from "../components/quote";
import { FilterType, filterInterviews, filterOfTypeExists } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";

const QuoteExplorer = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);

    const isLoading = useAppSelector((state) => state.project.isProjectLoading);
    const quotes = useAppSelector((state) => state.project.quotes);
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
    }, [interviews, selectedFilters, interviewMetadataValues]);
    const filteredQuotes = useMemo(() => {
        return quotes
            .filter((quote) =>
                filteredInterviews
                    .map((opt) => opt.id)
                    .includes(quote.interview),
            )
            .filter(
                (quote) =>
                    !filterOfTypeExists(
                        selectedFilters,
                        FilterType.Competitor,
                    ) ||
                    (quote.competitor &&
                        selectedFilters.some(
                            (filter) =>
                                filter.type === FilterType.Competitor &&
                                filter.values.includes(quote.competitor!),
                        )),
            );
    }, [filteredInterviews, quotes, selectedFilters]);

    if (isLoading) {
        return (
            <div className="mt-4">
                <Loader />
            </div>
        );
    }

    return (
        <section className="grid grid-cols-[350px_1fr] gap-4">
            <FilterSidebar
                activeFilters={selectedFilters}
                onChangeFilters={setSelectedFilters}
            />
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4 justify-end">
                    {window.waffle.flag_is_active("download_quotes") && (
                        <DropdownMenu
                            buttonClassName="text-sm"
                            menuItems={[
                                [
                                    {
                                        icon: "csv",
                                        title: "CSV",
                                        onClick: () =>
                                            (window.location.href = `/project/${projectId}/export/quotes/csv/`),
                                    },
                                    {
                                        icon: "xls",
                                        title: "Excel",
                                        onClick: () =>
                                            (window.location.href = `/project/${projectId}/export/quotes/xls/`),
                                    },
                                ],
                            ]}
                            title="Download as..."
                        />
                    )}
                    <div className="rounded-lg bg-gray-200 px-3.5 py-2 text-sm">{`${
                        filteredQuotes.length
                    } ${
                        filteredQuotes.length === 1 ? "quote" : "quotes"
                    }`}</div>
                </div>
                {filteredQuotes.map((quote) => (
                    <div className="quote-item" key={quote.id}>
                        <QuoteDisplay quote={quote} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default QuoteExplorer;
