import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, unstable_usePrompt, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { checkCeleryTask, parseQuestionResponse } from "../api";
import Button, { ButtonVariant } from "../components/button";
import Icon from "../components/icon";
import { initResponseSummary } from "../components/parser/helpers";
import InterviewParagraphs from "../components/parser/interview-paragraphs";
import InterviewSelector from "../components/parser/interview-selector";
import PromptOverrideModal from "../components/parser/prompt-override-modal";
import ResponseSummaryCard from "../components/parser/response-summary-card";
import ScrollShadowWrapper from "../components/scroll-shadow-wrapper";
import UnsavedCounter from "../components/unsaved-counter";
import { useAppDispatch, useAppSelector, useCheckWhenLeaving } from "../hooks";
import {
    resetQuestionResponses,
    setQuestionResponse,
} from "../stores/interview-parser";
import { setQuestions } from "../stores/project";
import {
    PromptType,
    Question,
    QuestionType,
    emptyQuote,
} from "../types/goldpan";
import { assertNonNull } from "../utils";
import { Messages } from "./message-list";

type AiQuestionResponse = {
    summary: string;
    question_id: string;
    options: {
        option_id?: string;
        paragraph_quote: string;
        paragraph_id: string;
    }[];
};

const ParseQuestionsApp: React.FC = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const dispatch = useAppDispatch();
    const [isParsingQuestionResponses, setIsParsingQuestionResponses] =
        useState<number[] | null>(null);
    const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
    const questions = useAppSelector((state) => state.project.questions);
    const questionResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );
    const dirtyCount = useAppSelector(
        (state) =>
            Object.values(state.interviewParser.questionResponses).filter(
                (summary) => summary.isDirty,
            ).length,
    );
    const unstartedQuestionIds = useAppSelector((state) =>
        questions
            .filter(
                (question) =>
                    !Object.keys(
                        state.interviewParser.questionResponses,
                    ).includes(`${question.id}`),
            )
            .map((question) => assertNonNull(question.id)),
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewId = useAppSelector(
        (state) => state.interviewParser.interviewId,
    );

    const shouldCheckBeforeLeavingPage = useMemo(
        () => dirtyCount > 0,
        [dirtyCount],
    );
    useCheckWhenLeaving(shouldCheckBeforeLeavingPage);
    unstable_usePrompt({
        message: "You have unsaved changes. Are you sure you want to leave?",
        when: ({ currentLocation, nextLocation }) =>
            shouldCheckBeforeLeavingPage &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    useEffect(() => {
        dispatch(resetQuestionResponses());
        if (!interviewId) {
            return;
        }

        const interviewResponseSummaries = questionResponseSummaries.filter(
            (response) => response.interview === interviewId,
        );
        for (const summary of interviewResponseSummaries) {
            dispatch(
                setQuestionResponse({
                    questionId: summary.question,
                    data: {
                        isDirty: false,
                        uuid: uuidv4(),
                        data: initResponseSummary(interviewId, summary),
                    },
                }),
            );
        }

        // don't include questionResponseSummaries here otherwise re-render unnecessarily
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interviewId, projectId]);

    const interview = useMemo(
        () => interviews.find((i) => i.id === interviewId) ?? null,
        [interviews, interviewId],
    );

    const handleParseQuestionResponses = useCallback(
        async (questionIds: number[]) => {
            if (!interviewId) {
                return;
            }
            Messages.removeAll();
            setIsParsingQuestionResponses(questionIds);
            const response = await parseQuestionResponse(
                projectId,
                interviewId,
                questionIds,
            );
            if (response) {
                checkCeleryTask<AiQuestionResponse[]>(
                    response.data.task_id,
                    (response) => {
                        response.forEach((responseSummary) => {
                            const questionId = parseInt(
                                responseSummary.question_id,
                            );
                            const question = questions.find(
                                (q) => q.id === questionId,
                            );
                            if (!question) {
                                Messages.error(
                                    "Question does not exist in project",
                                );
                                return;
                            }

                            const options = responseSummary.options.map(
                                (option) => {
                                    return {
                                        id: null,
                                        option:
                                            question.options.find(
                                                (o) =>
                                                    option.option_id &&
                                                    o.id ===
                                                        parseInt(
                                                            option.option_id,
                                                        ),
                                            )?.id ?? null,
                                        quotes: [
                                            {
                                                ...emptyQuote(interviewId),
                                                id: null,
                                                text: option.paragraph_quote,
                                                interview: interviewId,
                                                subtheme: null,
                                                interview_paragraph:
                                                    interview!.paragraphs.find(
                                                        (p) =>
                                                            p.ordinal ===
                                                            parseInt(
                                                                option.paragraph_id,
                                                            ),
                                                    )!.id,
                                            },
                                        ],
                                    };
                                },
                            );
                            dispatch(
                                setQuestionResponse({
                                    questionId,
                                    data: {
                                        data: {
                                            id: null,
                                            summary: responseSummary.summary,
                                            question: questionId,
                                            interview: interviewId,
                                            options:
                                                question.type ===
                                                    QuestionType.SINGLE &&
                                                options.length > 1
                                                    ? [options[0]]
                                                    : options,
                                        },
                                        uuid: uuidv4(),
                                        isDirty: true,
                                    },
                                }),
                            );
                        });
                        Messages.success("Responses ready for review!");
                    },
                    () => setIsParsingQuestionResponses(null),
                );
            } else {
                setIsParsingQuestionResponses(null);
            }
        },
        [interviewId, projectId, questions, dispatch, interview],
    );

    const handleUpdateQuestion = useCallback(
        (newQuestion: Question) => {
            dispatch(
                setQuestions(
                    questions.map((question) => {
                        if (question.id === newQuestion.id) {
                            return newQuestion;
                        }
                        return question;
                    }),
                ),
            );
        },
        [questions, dispatch],
    );

    const paragraphOptions = useMemo(
        () =>
            interview?.paragraphs.map((paragraph) => ({
                value: paragraph.id,
                label: `Paragraph ${paragraph.ordinal}`,
            })) ?? [],
        [interview],
    );

    return (
        <>
            <div className="w-full mt-4 mb-8 flex items-center justify-between">
                <div className="flex items-center">
                    <div className="w-[500px]">
                        <InterviewSelector
                            disabled={isParsingQuestionResponses !== null}
                            interviews={interviews}
                            selectedInterviewId={interviewId}
                        />
                    </div>
                    {interviewId !== null && (
                        <>
                            {isOverrideModalOpen && (
                                <PromptOverrideModal
                                    projectId={projectId}
                                    promptTypes={[
                                        PromptType.PARSE_QUESTION_RESPONSES,
                                    ]}
                                    onClose={() =>
                                        setIsOverrideModalOpen(false)
                                    }
                                />
                            )}
                            <div className="flex items-center gap-2 ml-4">
                                <Button
                                    disabled={
                                        isParsingQuestionResponses !== null
                                    }
                                    id="parse-all-question-responses-button"
                                    isLoading={
                                        isParsingQuestionResponses !== null &&
                                        isParsingQuestionResponses.length > 1
                                    }
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={() =>
                                        handleParseQuestionResponses(
                                            unstartedQuestionIds,
                                        )
                                    }
                                >
                                    Parse all unstarted
                                </Button>
                                <Button
                                    className="no-bg"
                                    disabled={
                                        isParsingQuestionResponses !== null
                                    }
                                    tooltip="AI prompt settings"
                                    onClick={() => setIsOverrideModalOpen(true)}
                                >
                                    <Icon icon="settings" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {interviewId !== null && (
                    <div className="flex items-center">
                        <UnsavedCounter dirtyCount={dirtyCount} />
                        <Link
                            className="button ml-4"
                            to={`/project/${projectId}/interview-parser/quotes/?interview=${interviewId}`}
                        >
                            Next step
                        </Link>
                    </div>
                )}
            </div>

            {interview !== null && (
                <div className="w-full flex flex-grow gap-2 ai-parser-height">
                    <div className="w-3/5 flex flex-col">
                        <div className="flex items-center">
                            <h3 className="mb-4">Question responses</h3>
                        </div>
                        <ScrollShadowWrapper>
                            {questions.map((question) => (
                                <ResponseSummaryCard
                                    interviewId={interview.id}
                                    interviewParagraphs={interview.paragraphs}
                                    isDisabled={
                                        isParsingQuestionResponses !== null
                                    }
                                    isParsingQuestionResponses={
                                        isParsingQuestionResponses
                                    }
                                    key={`${interviewId}_${question.id}`}
                                    paragraphOptions={paragraphOptions}
                                    question={question}
                                    onParseQuestionResponse={() =>
                                        handleParseQuestionResponses([
                                            assertNonNull(question.id),
                                        ])
                                    }
                                    onUpdateQuestion={handleUpdateQuestion}
                                />
                            ))}
                        </ScrollShadowWrapper>
                    </div>
                    <div className="w-2/5 flex flex-col">
                        <h3 className="mb-4">Transcript</h3>
                        <ScrollShadowWrapper>
                            <InterviewParagraphs
                                paragraphs={interview.paragraphs}
                            />
                        </ScrollShadowWrapper>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParseQuestionsApp;
