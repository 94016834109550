import UploadButton from "@rpldy/upload-button";
import UploadPreview, { PreviewComponentProps } from "@rpldy/upload-preview";
import Uploady, {
    useBatchAddListener,
    useBatchFinishListener,
    useItemProgressListener,
    useRequestPreSend,
} from "@rpldy/uploady";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { getCSRFToken } from "../../api";
import { Messages } from "../../apps/message-list";
import { CeleryTaskResponse } from "../../types/goldpan";

type Props = {
    interviewId: number;
    onUploadStarted: () => void;
    onUploadSuccess: (response: CeleryTaskResponse) => void;
    onUploadFailure: () => void;
};

const FileProgressBar = ({ id }: PreviewComponentProps) => {
    const { completed } = useItemProgressListener(id) || { completed: 0 };

    return (
        <div className="flex flex-1 h-3 rounded-lg bg-gray-200">
            <div
                className="flex h-3 rounded-lg bg-gp-blue"
                style={{
                    width: `${completed}%`,
                }}
            ></div>
        </div>
    );
};

const UploadWithProgressPreview = ({
    onUploadFailure,
    onUploadStarted,
    onUploadSuccess,
}: Props) => {
    const [filename, setFilename] = useState<string | null>(null);
    useBatchAddListener((batch) => {
        setFilename(batch.items[0].file.name);
        onUploadStarted();
    });
    useBatchFinishListener((batch) => {
        const status = batch.items[0].uploadStatus;
        const response = batch.items[0].uploadResponse?.data;
        if (status !== 200) {
            const msg = response?.message ?? "File upload failed";
            Messages.error(msg);
            setFilename(null);
            onUploadFailure();
        } else {
            const response = batch.items[0].uploadResponse
                ?.data as CeleryTaskResponse;
            onUploadSuccess(response);
        }
    });
    useRequestPreSend(() => {
        return {
            options: {
                destination: {
                    headers: {
                        "X-CSRFToken": getCSRFToken(),
                    },
                },
            },
        };
    });

    return (
        <div className="w-64">
            {filename ? (
                <div className="flex items-center gap-4">
                    <div className="text-center">{filename}</div>
                    <UploadPreview PreviewComponent={FileProgressBar} />
                </div>
            ) : (
                <UploadButton className="button">Upload audio</UploadButton>
            )}
        </div>
    );
};

const AudioFileUpload = (props: Props) => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    return (
        <Uploady
            accept="audio/*"
            debug
            destination={{
                url: `/api/project/${projectId}/interview/${props.interviewId}/audio-file-upload/`,
            }}
            multiple={false}
        >
            <UploadWithProgressPreview {...props} />
        </Uploady>
    );
};

export default AudioFileUpload;
