import {
    ColDef,
    RowClickedEvent,
    ValueFormatterParams,
    ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { compact } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "../components/input";
import Loader from "../components/loader";
import Outcome from "../components/outcome";
import { useAppSelector } from "../hooks";
import { Interview, InterviewOutcome } from "../types/goldpan";
import { interviewUrl } from "../urls";

const InterviewTable = () => {
    const navigate = useNavigate();
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const isLoading = useAppSelector((state) => state.project.isProjectLoading);
    const [filter, setFilter] = useState("");
    const rowData = useAppSelector((state) => state.project.interviews);

    const colDefs: ColDef<Interview>[] = useMemo(
        () =>
            compact([
                {
                    field: "code",
                    flex: 1,
                },
                { field: "name", flex: 1 },
                { field: "date", flex: 1, sort: "desc" },
                window.goldpanUser?.permissions.includes(
                    "core.change_interview",
                ) && { field: "status", flex: 1 },
                {
                    field: "outcome",
                    flex: 1,
                    comparator: (
                        outcomeA?: InterviewOutcome,
                        outcomeB?: InterviewOutcome,
                    ) =>
                        outcomeA && outcomeB
                            ? outcomeA.name.localeCompare(outcomeB.name)
                            : -1,
                    filterValueGetter: (params: ValueGetterParams<Interview>) =>
                        params.node?.data?.outcome?.name ?? "",
                    valueFormatter: (params: ValueFormatterParams<Interview>) =>
                        params.node?.data?.outcome?.name ?? "",
                    cellRenderer: (
                        params: CustomCellRendererProps<
                            unknown,
                            InterviewOutcome
                        >,
                    ) =>
                        params.value && (
                            <div className="h-full w-fit flex items-center">
                                <Outcome outcome={params.value} />
                            </div>
                        ),
                },
            ]),
        [],
    );

    if (isLoading) {
        return (
            <div className="mt-4">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <Input
                className="w-64 mb-2"
                placeholder="Search..."
                value={filter}
                onChange={(value) => setFilter(value)}
            />
            <div className="ag-theme-quartz h-[500px]">
                <AgGridReact
                    columnDefs={colDefs}
                    domLayout={rowData.length <= 10 ? "autoHeight" : "normal"}
                    quickFilterText={filter}
                    rowClass="cursor-pointer"
                    rowData={rowData}
                    onRowClicked={(evt: RowClickedEvent<Interview>) => {
                        if (evt.data) {
                            navigate(interviewUrl(projectId, evt.data.slug));
                        }
                    }}
                />
            </div>
        </>
    );
};

export default InterviewTable;
