import { unionBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Button, { ButtonVariant } from "../../components/button";
import Input from "../../components/input";
import { useAppSelector } from "../../hooks";
import { Interview, RawInterviewMetadataValues } from "../../types/goldpan";
import { interviewUrl } from "../../urls";
import { defaultSelectProps } from "../../utils";

const InterviewForm = ({
    disabled,
    interview,
    interviewIsValid,
    isSubmitting,
    onSubmit,
    projectId,
}: {
    disabled?: boolean;
    onSubmit: (
        code: string,
        name: string,
        outcome: number | null,
        metadata: RawInterviewMetadataValues,
    ) => void;
    isSubmitting: boolean;
    interviewIsValid: boolean | null;
    projectId: number;
    interview: Interview | null;
}) => {
    const outcomes = useAppSelector((state) => state.project.outcomes);
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interviewMetadataFieldValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const [interviewMetadata, setInterviewMetadata] =
        useState<RawInterviewMetadataValues>({});
    const [interviewCode, setInterviewCode] = useState("");
    const [interviewName, setInterviewName] = useState("");
    const [interviewOutcome, setInterviewOutcome] = useState<number | null>(
        null,
    );

    const outcomeOptions = useMemo(
        () =>
            outcomes.map((outcome) => ({
                value: outcome.id,
                label: outcome.name,
            })),
        [outcomes],
    );

    const handleSumbit = useCallback(() => {
        onSubmit(
            interviewCode,
            interviewName,
            interviewOutcome,
            interviewMetadata,
        );
    }, [
        interviewCode,
        interviewMetadata,
        interviewName,
        interviewOutcome,
        onSubmit,
    ]);

    return (
        <>
            <div className="my-4">
                <Input
                    disabled={disabled}
                    id="interview-code"
                    placeholder="Interview code"
                    type="text"
                    value={interviewCode}
                    onChange={setInterviewCode}
                />
            </div>
            <div className="mb-4">
                <Input
                    disabled={disabled}
                    id="interview-name"
                    placeholder="Interview name"
                    type="text"
                    value={interviewName}
                    onChange={setInterviewName}
                />
            </div>
            <Select
                {...defaultSelectProps}
                className="mb-4"
                id="outcome-select"
                isDisabled={disabled}
                menuPlacement="top"
                options={outcomeOptions}
                placeholder="Outcome"
                value={
                    outcomeOptions.find((o) => o.value === interviewOutcome) ??
                    null
                }
                onChange={(opt) => setInterviewOutcome(opt!.value)}
            />

            {interviewMetadataFields.map((field) => (
                <div className="mb-4" key={field.id}>
                    <CreatableSelect
                        {...defaultSelectProps}
                        id={`inteview-metadata-field-${field.id}`}
                        isDisabled={disabled}
                        menuPlacement="top"
                        options={
                            interviewMetadataFieldValues[field.id]
                                ? unionBy(
                                      interviewMetadataFieldValues[
                                          field.id
                                      ].map((val) => ({
                                          value: val.data,
                                          label: val.data,
                                      })),
                                      "value",
                                  )
                                : []
                        }
                        placeholder={`Field: ${field.display_title}`}
                        value={
                            interviewMetadata[field.id]
                                ? {
                                      value: interviewMetadata[field.id],
                                      label: interviewMetadata[field.id],
                                  }
                                : null
                        }
                        onChange={(opt) => {
                            setInterviewMetadata({
                                ...interviewMetadata,
                                [field.id]: opt!.value,
                            });
                        }}
                        onCreateOption={(value) =>
                            setInterviewMetadata({
                                ...interviewMetadata,
                                [field.id]: value,
                            })
                        }
                    />
                </div>
            ))}

            {!disabled && !interview && (
                <Button
                    className="mt-4 w-48"
                    disabled={!interviewIsValid}
                    icon="save"
                    id="submit-json-transcipt"
                    isLoading={isSubmitting}
                    variant={ButtonVariant.SUCCESS}
                    onClick={handleSumbit}
                >
                    Create interview
                </Button>
            )}

            {interview && (
                <div className="mt-4 flex items-center gap-4">
                    <Link
                        className="button"
                        to={interviewUrl(projectId, interview.slug)}
                    >
                        View interview
                    </Link>
                    <div>or</div>
                    <Link
                        className="button primary"
                        to={`/project/${projectId}/interview-parser/metadata/?interview=${interview.id}`}
                    >
                        Proceed to parse metadata
                    </Link>
                </div>
            )}
        </>
    );
};

export default InterviewForm;
