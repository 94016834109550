import { useCallback, useMemo, useState } from "react";
import { Link, unstable_usePrompt, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { checkCeleryTask, parseNewCompetitors, saveCompetitor } from "../api";
import Button, { ButtonVariant } from "../components/button";
import Input from "../components/input";
import InterviewParagraphs from "../components/parser/interview-paragraphs";
import InterviewSelector from "../components/parser/interview-selector";
import ScrollShadowWrapper from "../components/scroll-shadow-wrapper";
import UnsavedCounter from "../components/unsaved-counter";
import { useAppDispatch, useAppSelector, useCheckWhenLeaving } from "../hooks";
import { setCompetitors } from "../stores/project";
import { Competitor, WithUUID } from "../types/goldpan";
import { Messages } from "./message-list";

type AiCompetitorResponse = {
    name: string;
};

const validateCompetitor = (competitor: Competitor) => {
    let isValid = true;
    if (!competitor.name || competitor.name.trim() === "") {
        isValid = false;
        Messages.error("Competitor name is not valid");
    }
    if (competitor.id) {
        isValid = false;
        Messages.error("Competitor already saved");
    }
    return isValid;
};

const ParseInterviewMetadata: React.FC = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const dispatch = useAppDispatch();
    const [isParsingNewCompetitors, setIsParsingNewCompetitors] =
        useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [proposedCompetitors, setProposedCompetitors] = useState<
        WithUUID<Competitor>[]
    >([]);
    const competitors = useAppSelector((state) => state.project.competitors);
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewId = useAppSelector(
        (state) => state.interviewParser.interviewId,
    );

    const dirtyCount = useMemo(
        () => proposedCompetitors.length,
        [proposedCompetitors],
    );
    const shouldCheckBeforeLeavingPage = useMemo(
        () => dirtyCount > 0,
        [dirtyCount],
    );
    useCheckWhenLeaving(shouldCheckBeforeLeavingPage);
    unstable_usePrompt({
        message: "You have unsaved changes. Are you sure you want to leave?",
        when: ({ currentLocation, nextLocation }) =>
            shouldCheckBeforeLeavingPage &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    const interview = useMemo(
        () => interviews.find((i) => i.id === interviewId) ?? null,
        [interviews, interviewId],
    );

    const handleParseNewCompetitors = useCallback(async () => {
        if (!interviewId) {
            return;
        }
        Messages.removeAll();
        setIsParsingNewCompetitors(true);
        const response = await parseNewCompetitors(projectId, interviewId);
        if (response) {
            checkCeleryTask<AiCompetitorResponse[]>(
                response.data.task_id,
                (response) => {
                    if (response.length > 0) {
                        setProposedCompetitors(
                            response.map((competitor) => ({
                                data: {
                                    id: null,
                                    name: competitor.name,
                                },
                                uuid: uuidv4(),
                                isDirty: true,
                            })),
                        );
                        Messages.success(
                            "Suggested competitors ready for review!",
                        );
                    } else {
                        Messages.success("No new competitors to suggest");
                    }
                },
                () => setIsParsingNewCompetitors(false),
            );
        } else {
            setIsParsingNewCompetitors(false);
        }
    }, [interviewId, projectId]);

    const handleSaveCompetitor = useCallback(
        async (uuid: string) => {
            Messages.removeAll();
            const competitor = proposedCompetitors.find((c) => c.uuid === uuid);
            if (competitor === undefined) {
                Messages.error("Cannot find competitor " + uuid);
                return;
            }

            if (interviewId && validateCompetitor(competitor.data)) {
                setIsSaving(true);
                const response = await saveCompetitor(
                    projectId,
                    competitor.data,
                );
                setIsSaving(false);
                if (!response) {
                    return;
                }
                setProposedCompetitors(
                    proposedCompetitors.filter((c) => c.uuid !== uuid),
                );
                dispatch(setCompetitors(competitors.concat([response.data])));
                Messages.success("Saved successfully");
            }
        },
        [proposedCompetitors, interviewId, projectId, dispatch, competitors],
    );

    const handleUpdateCompetitor = useCallback(
        (newCompetitor: WithUUID<Competitor>) => {
            setProposedCompetitors(
                proposedCompetitors.map((c) => {
                    if (c.uuid === newCompetitor.uuid) {
                        return newCompetitor;
                    }
                    return c;
                }),
            );
        },
        [proposedCompetitors],
    );

    const handleIgnoreCompetitor = useCallback(
        (uuid: string) => {
            setProposedCompetitors(
                proposedCompetitors.filter((c) => c.uuid !== uuid),
            );
        },
        [proposedCompetitors],
    );

    return (
        <>
            <div className="w-full mt-4 mb-8 flex items-center justify-between">
                <div className="flex items-center">
                    <div className="w-[500px]">
                        <InterviewSelector
                            disabled={isParsingNewCompetitors}
                            interviews={interviews}
                            selectedInterviewId={interviewId}
                        />
                    </div>
                    {interviewId !== null && (
                        <>
                            <div className="flex items-center gap-2 ml-4">
                                <Button
                                    id="parse-new-competitors-button"
                                    isLoading={isParsingNewCompetitors}
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={handleParseNewCompetitors}
                                >
                                    Suggest competitors
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {interviewId !== null && (
                    <div className="flex items-center">
                        <UnsavedCounter dirtyCount={dirtyCount} />
                        <Link
                            className="button ml-4"
                            to={`/project/${projectId}/interview-parser/decisions/?interview=${interviewId}`}
                        >
                            Next step
                        </Link>
                    </div>
                )}
            </div>

            {interview !== null && (
                <div className="w-full flex flex-grow gap-2 ai-parser-height">
                    <div className="w-3/5 flex flex-col">
                        <div className="flex items-center">
                            <h3 className="mb-4">Competitors</h3>
                        </div>
                        <ScrollShadowWrapper>
                            {proposedCompetitors.map((competitor) => (
                                <div
                                    className="card-slim flex items-center justify-between mb-2"
                                    key={competitor.uuid}
                                >
                                    <div className="flex items-center">
                                        <div className="font-bold text-sm mr-4 text-gp-blue-light">
                                            SUGGESTED
                                        </div>
                                        <Input
                                            value={competitor.data.name}
                                            onChange={(val) =>
                                                handleUpdateCompetitor({
                                                    ...competitor,
                                                    isDirty: true,
                                                    data: {
                                                        id: null,
                                                        name: val,
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex items-center">
                                        <Button
                                            className="text-sm mr-1"
                                            disabled={isSaving}
                                            icon="save"
                                            variant={ButtonVariant.SUCCESS}
                                            onClick={() =>
                                                handleSaveCompetitor(
                                                    competitor.uuid,
                                                )
                                            }
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            className="text-sm mr-1"
                                            disabled={isSaving}
                                            icon="delete"
                                            variant={ButtonVariant.DANGER}
                                            onClick={() =>
                                                handleIgnoreCompetitor(
                                                    competitor.uuid,
                                                )
                                            }
                                        >
                                            Ignore
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            {competitors.map((competitor) => (
                                <div
                                    className="card-slim flex items-center mb-2"
                                    key={competitor.id}
                                >
                                    <div className="font-bold text-sm mr-4 text-gray-400">
                                        EXISTING
                                    </div>
                                    <div>{competitor.name}</div>
                                </div>
                            ))}
                        </ScrollShadowWrapper>
                    </div>
                    <div className="w-2/5 flex flex-col">
                        <h3 className="mb-4">Transcript</h3>
                        <ScrollShadowWrapper>
                            <InterviewParagraphs
                                paragraphs={interview.paragraphs}
                            />
                        </ScrollShadowWrapper>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParseInterviewMetadata;
