import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

import Icon, { Icons } from "./icon";

type MenuItem = {
    icon: Icons;
    title: string;
    onClick: () => void;
};
type Props = {
    title: string;
    menuItems: MenuItem[][];
    buttonClassName?: string;
    icon?: Icons;
    anchor?: string;
    disabled?: boolean;
};

const DropdownMenu = ({
    anchor,
    buttonClassName,
    disabled = false,
    icon = "chevron_down",
    menuItems,
    title,
}: Props) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton
                    className={classNames(
                        buttonClassName,
                        "inline-flex w-full justify-center button dropdown-menu-btn",
                    )}
                    disabled={disabled}
                >
                    {title}
                    <Icon
                        aria-hidden="true"
                        className="ml-2 h-5 w-5"
                        icon={icon}
                    />
                </MenuButton>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems
                    anchor={
                        anchor === "right start" ? "right start" : undefined
                    }
                    className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                    {menuItems.map((group, index) => (
                        <div className="px-1 py-1" key={index}>
                            {group.map((item) => (
                                <MenuItem key={item.title}>
                                    {({ focus }) => (
                                        <button
                                            className={`${
                                                focus
                                                    ? "bg-blue-200 text-gp-blue"
                                                    : "text-base"
                                            } group flex w-full items-center rounded-md p-3 dropdown-menu-item truncate`}
                                            onClick={item.onClick}
                                        >
                                            <Icon
                                                aria-hidden={true}
                                                className="mr-2 h-5 w-5"
                                                icon={item.icon}
                                            />
                                            {item.title}
                                        </button>
                                    )}
                                </MenuItem>
                            ))}
                        </div>
                    ))}
                </MenuItems>
            </Transition>
        </Menu>
    );
};

export default DropdownMenu;
