import { sortBy } from "lodash";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Question, ResponseSummary } from "../../types/goldpan";
import Card from "../layout/card";
import CardTitle from "../layout/card-title";
import SubCard from "../layout/sub-card";
import QuoteSlim from "./quote-slim";

const QuestionGroupResponses = ({
    groupQuestions,
    interviewResponseSummaries,
    title,
}: {
    title: string;
    groupQuestions: Question[];
    interviewResponseSummaries: ResponseSummary[];
}) => {
    return (
        <Card>
            <CardTitle className="mb-2">{title}</CardTitle>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
                <Masonry gutter="1.5rem">
                    {groupQuestions
                        .filter(
                            (question) =>
                                interviewResponseSummaries.filter(
                                    (response) =>
                                        response.question === question.id,
                                ).length > 0,
                        )
                        .map((question) => {
                            const responses = interviewResponseSummaries.filter(
                                (response) => response.question === question.id,
                            );

                            return (
                                <SubCard key={question.id}>
                                    <div className="font-bold text-lg">
                                        {question.text}
                                    </div>
                                    {question.subtext && (
                                        <div className="text-sm italic">
                                            {question.subtext}
                                        </div>
                                    )}

                                    <div className="flex flex-col mt-2">
                                        {responses.map((response) => {
                                            const sortedOptions = sortBy(
                                                response.options,
                                                (responseOption) => {
                                                    const selectedOption =
                                                        question.options.find(
                                                            (opt) => {
                                                                return (
                                                                    opt.id ===
                                                                    responseOption.option
                                                                );
                                                            },
                                                        );
                                                    return (
                                                        selectedOption?.ordinal ??
                                                        1
                                                    );
                                                },
                                            );
                                            return (
                                                <div
                                                    className="flex flex-col "
                                                    id={`question_response_${response.id}`}
                                                    key={response.id}
                                                >
                                                    <div className="label-gray mb-2">
                                                        Summary
                                                    </div>
                                                    <div className="font-medium pb-4 border-b whitespace-pre-line">
                                                        {response.summary}
                                                    </div>
                                                    {sortedOptions.map(
                                                        (responseOption) => {
                                                            const option =
                                                                question.options.find(
                                                                    (opt) =>
                                                                        opt.id ===
                                                                        responseOption.option,
                                                                );
                                                            return (
                                                                <div
                                                                    key={
                                                                        responseOption.id
                                                                    }
                                                                >
                                                                    <div className="font-bold my-2 text-white rounded-md bg-gp-accent p-2 w-fit">
                                                                        {
                                                                            option?.text
                                                                        }
                                                                    </div>
                                                                    <div className="flex flex-col items-center">
                                                                        {responseOption.quotes.map(
                                                                            (
                                                                                quote,
                                                                            ) => (
                                                                                <QuoteSlim
                                                                                    key={
                                                                                        quote.id
                                                                                    }
                                                                                    quote={
                                                                                        quote
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </SubCard>
                            );
                        })}
                </Masonry>
            </ResponsiveMasonry>
        </Card>
    );
};

export default QuestionGroupResponses;
