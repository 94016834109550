import classNames from "classnames";

import { Quote } from "../../types/goldpan";
import { getSentimentClassnames } from "../../utils";

const QuoteSlim = ({ quote }: { quote: Quote }) => {
    return (
        <div
            className={classNames(
                "border-l-8 p-5 text-gray-800 w-full",
                getSentimentClassnames(quote.sentiment).border,
                getSentimentClassnames(quote.sentiment).background,
            )}
            key={quote.id}
        >
            <div className="w-full">
                <p className="text-xs {{ supporting_text_color }}">
                    Supporting quote
                </p>
                <p className="text-base font-semibold max-w-2xl mb-3 text-gray-900 whitespace-pre-line">
                    {quote.text}
                </p>
            </div>
            <div className="flex gap-2 justify-between w-full text-xs">
                {quote.interview_paragraph && (
                    <button
                        className="text-white bg-gp-blue hover:bg-gp-blue-light rounded-md p-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                        role="button"
                        onClick={() => {
                            [].forEach.call(
                                document.querySelectorAll(
                                    ".interview-paragraph.highlight",
                                ),
                                function (el: HTMLElement) {
                                    el.classList.remove("highlight");
                                },
                            );
                            const paragraph = document.getElementById(
                                "paragraph_" + quote.interview_paragraph,
                            );
                            paragraph?.classList.add("highlight");
                            paragraph?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }}
                    >
                        See in transcript
                    </button>
                )}
            </div>
        </div>
    );
};

export default QuoteSlim;
