import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { flatMap, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { saveInterview } from "../api";
import DropdownMenu from "../components/dropdown-menu";
import FilterSidebar from "../components/filters/filter-sidebar";
import Icon, { Icons } from "../components/icon";
import Input from "../components/input";
import InterviewHeading from "../components/interview-heading";
import { filterInterviews } from "../filters";
import { useAppDispatch, useAppSelector, useFilterParams } from "../hooks";
import { generateInterviewPdf } from "../pdf";
import { setInterviews } from "../stores/project";
import {
    DecisionReason,
    Interview,
    InterviewStatus,
    Quote,
    Sentiment,
} from "../types/goldpan";
import { interviewParserUrl, interviewUrl } from "../urls";
import { assertNonNull, publishConfetti } from "../utils";
import { Messages } from "./message-list";

const InterviewList = () => {
    const [filter, setFilter] = useState("");
    const [isPublishing, setIsPublishing] = useState<{
        state: boolean;
        id: number;
    }>({
        state: false,
        id: -1,
    });
    const [sortParam, setSortParam] = useState<{
        key: string;
        method: "asc" | "desc";
    }>({
        key: "date",
        method: "desc",
    });
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const interviews = useAppSelector((state) => state.project.interviews);

    const navigate = useNavigate();
    const stateProject = useAppSelector((state) => state.project);

    const handleDownloadPDF = useCallback(
        (interview: Interview) => {
            const interviewDecisionReasons =
                stateProject.decisionReasons.filter(
                    (decision: DecisionReason) =>
                        decision.interview === interview?.id,
                );

            const interviewResponseSummaries =
                stateProject.questionResponseSummaries.filter(
                    (summary) => summary.interview === interview?.id,
                );

            const decisionReasonQuotes = flatMap(
                interviewDecisionReasons.map((decision) =>
                    decision.quotes.map((quote) => quote.id!),
                ),
            );

            const responseSummaryQuotes = flatMap(
                interviewResponseSummaries.map((response) =>
                    flatMap(
                        response.options.map((option) =>
                            option.quotes.map((quote) => quote.id!),
                        ),
                    ),
                ),
            );

            const linkedQuoteIds = [
                ...decisionReasonQuotes,
                ...responseSummaryQuotes,
            ];

            const interviewQuotes = stateProject.quotes.filter(
                (quote: Quote) => quote.interview === interview?.id,
            );
            const positiveQuotes = interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.POSITIVE &&
                    !linkedQuoteIds.includes(quote.id!),
            );
            const negativeQuotes = interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.NEGATIVE &&
                    !linkedQuoteIds.includes(quote.id!),
            );
            const neutralQuotes = interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.NEUTRAL &&
                    !linkedQuoteIds.includes(quote.id!),
            );
            const decisionExist = stateProject.subthemes.some((subtheme) => {
                const decisions = interviewDecisionReasons.filter(
                    (decision) => decision.subtheme === subtheme.id,
                );
                return decisions.length > 0;
            });
            const questionResponsesExist = stateProject.questions.some(
                (question) => {
                    const responses = interviewResponseSummaries.filter(
                        (response) => response.question === question.id,
                    );
                    return responses.length > 0;
                },
            );

            generateInterviewPdf(
                interview,
                stateProject.interviewMetadataFields,
                stateProject.subthemes,
                decisionExist ? interviewDecisionReasons : [],
                stateProject.questions,
                questionResponsesExist ? interviewResponseSummaries : [],
                positiveQuotes,
                negativeQuotes,
                neutralQuotes,
            );
        },
        [stateProject],
    );

    const dispatch = useAppDispatch();

    const handleStatus = useCallback(
        (interview: Interview, status: InterviewStatus) => {
            if (!interview || interview.status === status) {
                return;
            }
            setIsPublishing({ state: true, id: interview.id });
            saveInterview(projectId, {
                ...interview,
                status,
            })
                .then((response) => {
                    if (response) {
                        dispatch(
                            setInterviews(
                                interviews.map((i) => {
                                    if (i.id !== response.data.id) {
                                        return i;
                                    }
                                    return response.data;
                                }),
                            ),
                        );

                        if (status == InterviewStatus.DRAFT) {
                            Messages.success(
                                "Interview is now no longer visible to all project members",
                            );
                        }
                        if (status == InterviewStatus.PUBLISHED) {
                            publishConfetti();
                            Messages.success(
                                "Interview is now visible to all project members",
                            );
                        }
                    }
                })
                .finally(() => setIsPublishing({ state: false, id: -1 }));
        },
        [projectId, dispatch, interviews],
    );

    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );
    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        ).filter(
            (interview) =>
                filter === "" ||
                interview.name.toLowerCase().includes(filter.toLowerCase()) ||
                interview.code.toLowerCase().includes(filter.toLowerCase()),
        );
    }, [interviews, selectedFilters, interviewMetadataValues, filter]);

    const handleSortChange = useCallback((field: string) => {
        setSortParam((prevSortParam) => {
            if (prevSortParam.key === field) {
                if (prevSortParam.method === "desc") {
                    return { key: field, method: "asc" };
                } else {
                    return { key: field, method: "desc" };
                }
            }
            return { key: field, method: "desc" };
        });
    }, []);
    const generateMenuItems = useCallback(
        (interview: Interview) => {
            if (!window.goldpanUser?.is_staff) {
                return [];
            }
            const items = [];
            if (
                interview.status === InterviewStatus.PUBLISHED &&
                window.goldpanUser?.permissions.includes(
                    "core.change_interview",
                )
            ) {
                const icon: Icons = "edit";
                items.push({
                    icon: icon,
                    title: "Return to Draft",
                    onClick: () =>
                        handleStatus(interview, InterviewStatus.DRAFT),
                });
            }
            if (
                interview.status === InterviewStatus.DRAFT &&
                window.goldpanUser?.permissions.includes(
                    "core.change_interview",
                )
            ) {
                const icon: Icons = "edit";
                items.push({
                    icon: icon,
                    title: "Publish Interview",
                    onClick: () =>
                        handleStatus(interview, InterviewStatus.PUBLISHED),
                });
            }
            if (window.waffle.flag_is_active("interview_pdf")) {
                const icon: Icons = "download";
                items.push({
                    icon: icon,
                    title: "Download PDF",
                    onClick: () => handleDownloadPDF(interview),
                });
            }
            if (
                window.goldpanUser?.permissions.includes(
                    "core.use_interview_ai_parser",
                )
            ) {
                const icon: Icons = "beaker";
                items.push({
                    icon: icon,
                    title: "Go to Interview Parser",
                    onClick: () =>
                        navigate(interviewParserUrl(projectId, interview.id)),
                });
            }
            return items;
        },
        [navigate, projectId, handleDownloadPDF, handleStatus],
    );
    const renderIcon = (column: string) => {
        if (sortParam.key != column) {
            return null;
        }
        if (sortParam.method == "asc") {
            return <Icon className="ml-2 mt-0.5" icon="arrow_down_long" />;
        }
        if (sortParam.method == "desc") {
            return <Icon className="ml-2 mt-0.5" icon="arrow_up_long" />;
        }
        return null;
    };

    const sortedFilteredInterviews = useMemo(() => {
        return orderBy(filteredInterviews, sortParam.key, sortParam.method);
    }, [sortParam, filteredInterviews]);
    return (
        <section className="flex flex-col xl:flex-row gap-4">
            <div className="xl:w-1/5 w-full">
                <Input
                    className="w-full mb-4"
                    placeholder="Search interviews..."
                    value={filter}
                    onChange={(value) => setFilter(value)}
                />
                <FilterSidebar
                    activeFilters={selectedFilters}
                    hideCompetitors
                    onChangeFilters={setSelectedFilters}
                />
            </div>
            <div className="w-full h-min bg-gp-beige border-2 border-gp-beige-border rounded-xl divide-y">
                <div className="my-5 ml-12 flex flex-row">
                    <div className="w-1/6">
                        <InterviewHeading
                            icon={renderIcon("name")}
                            name="name"
                            title="INTERVIEW"
                            onClick={handleSortChange}
                        />
                    </div>
                    <div className="w-full">
                        <div className="grid grid-cols-auto-fit-100 ">
                            <div className="text-center ">
                                <InterviewHeading
                                    icon={renderIcon("outcome.name")}
                                    name="outcome.name"
                                    title="OUTCOME"
                                    onClick={handleSortChange}
                                />
                            </div>
                            {window.goldpanUser?.is_staff && (
                                <div className="text-center">
                                    <InterviewHeading
                                        icon={renderIcon("status")}
                                        name="status"
                                        title="STATUS"
                                        onClick={handleSortChange}
                                    />
                                </div>
                            )}
                            <div className="text-center">
                                <InterviewHeading
                                    icon={renderIcon("date")}
                                    name="date"
                                    title="DATE OF INTERVIEW"
                                    onClick={handleSortChange}
                                />
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <AnimatePresence>
                    {filteredInterviews.length == 0 && (
                        <div className="px-12 py-4">No results!</div>
                    )}
                    {sortedFilteredInterviews.map((interview, index) => {
                        return (
                            <motion.div
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                key={interview.id}
                            >
                                <div
                                    className={`bg-white  py-4 flex flex-row ${
                                        index == filteredInterviews.length - 1
                                            ? "rounded-b-xl"
                                            : ""
                                    }`}
                                >
                                    <div className="w-1/6 ml-12">
                                        <div className="flex flex-col">
                                            <div className="text-xl ">
                                                {interview.name}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {interview.code}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="grid grid-cols-auto-fit-100">
                                            <div
                                                className={`font-semibold text-center pt-3`}
                                            >
                                                {interview.outcome ? (
                                                    <span
                                                        className={`rounded-full py-1.5 px-3 truncate overflow-hidden
                                                                bg-gp-sentiment-${interview.outcome.sentiment}-light text-gp-sentiment-${interview.outcome.sentiment}

                                                        `}
                                                    >
                                                        {interview.outcome.name}
                                                    </span>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </div>
                                            {window.goldpanUser?.is_staff && (
                                                <div className="pt-3 text-center capitalize">
                                                    {interview.status ? (
                                                        <span
                                                            className={`py-1.5 px-3 rounded-full font-semibold text-center w-full ${
                                                                interview.status ==
                                                                "draft"
                                                                    ? "bg-gray-200 text-gray-500"
                                                                    : ""
                                                            } ${
                                                                interview.status ==
                                                                "published"
                                                                    ? "bg-gp-sentiment-positive-light text-gp-sentiment-positive"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <span className="text-center">
                                                                {
                                                                    interview.status
                                                                }
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            )}

                                            <div className="text-sm text-center pt-3">
                                                {dayjs(interview.date).format(
                                                    "MMM D, YYYY",
                                                )}
                                            </div>
                                            <div className="flex gap-5 justify-center  xs:pl-16">
                                                <div className="text-center">
                                                    <Link
                                                        to={interviewUrl(
                                                            project.id,
                                                            interview.slug,
                                                        )}
                                                    >
                                                        <button className="rounded-full bg-white border-2 border-gp-blue-100 py-2 px-4 text-sm text-gp-blue-100 hover:bg-gp-blue-200">
                                                            View
                                                        </button>
                                                    </Link>
                                                </div>
                                                {isPublishing.state &&
                                                    isPublishing.id ==
                                                        interview.id && (
                                                        <div role="status">
                                                            <Icon
                                                                className="h-10 w-10 ml-4 loader-spinner animate-spin"
                                                                icon="spinner"
                                                            />
                                                        </div>
                                                    )}
                                                {!(
                                                    isPublishing.state &&
                                                    interview.id ==
                                                        isPublishing.id
                                                ) &&
                                                    generateMenuItems(interview)
                                                        .length > 0 && (
                                                        <DropdownMenu
                                                            anchor="right start"
                                                            buttonClassName={`!bg-white hover:!bg-white !shadow-none !hidden lg:!block`}
                                                            disabled={
                                                                isPublishing.state
                                                            }
                                                            icon="kebab_menu"
                                                            menuItems={[
                                                                generateMenuItems(
                                                                    interview,
                                                                ),
                                                            ]}
                                                            title=""
                                                        ></DropdownMenu>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </div>
        </section>
    );
};

export default InterviewList;
