import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getProjects } from "../api";
import Loader from "../components/loader";
import { useAppDispatch } from "../hooks";
import { resetProject } from "../stores/project";
import { Project } from "../types/goldpan";

const ProjectSelector = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        dispatch(resetProject());
        getProjects().then((response) => {
            if (!response) {
                return;
            }
            setProjects(response.data);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <div className="mt-16">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <header className="flex flex-col pb-5 border-b-4 border-black mb-8">
                <p className="font-medium text-gray-800">
                    👋 Welcome to Goldpan
                </p>
                <div className="flex justify-between items-center">
                    <h1 className="text-5xl font-bold">Select a project</h1>
                    {window.goldpanUser?.permissions.includes(
                        "core.add_project",
                    ) && (
                        <Link className="button primary" to="/create-project/">
                            Create project
                        </Link>
                    )}
                </div>
            </header>
            <section className="flex flex-col gap-4 pb-8" id="project-selector">
                {projects.map((project) => (
                    <Link
                        className="flex w-full flex-col rounded-lg border border-gray-200 bg-white text-slate-900 p-8 shadow hover:no-underline hover:cursor-pointer hover:border-blue-400"
                        key={project.id}
                        to={`/project/${project.id}/`}
                    >
                        <h2 className="text-2xl font-bold">
                            {project.client_name}
                        </h2>
                        {project.title}
                    </Link>
                ))}
            </section>
        </>
    );
};

export default ProjectSelector;
