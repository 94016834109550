import { flatMap, groupBy, reverse, sortBy } from "lodash";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import FilterSidebar from "../components/filters/filter-sidebar";
import Loader from "../components/loader";
import { filterInterviews } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";
import { questionPageUrl } from "../urls";
import { assertNonNull, getPercentage } from "../utils";

const QuestionsExplorer = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const isLoading = useAppSelector((state) => state.project.isProjectLoading);
    const questions = useAppSelector((state) => state.project.questions);
    const questionResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
    }, [interviews, selectedFilters, interviewMetadataValues]);

    if (isLoading) {
        return (
            <div className="mt-4">
                <Loader />
            </div>
        );
    }

    return (
        <section className="grid grid-cols-[350px_1fr] gap-4">
            <FilterSidebar
                activeFilters={selectedFilters}
                hideCompetitors
                onChangeFilters={setSelectedFilters}
            />
            <div className="flex flex-col gap-2">
                {questions.map((question) => {
                    const allOptionAnswers = flatMap(
                        questionResponseSummaries
                            .filter(
                                (response) => response.question === question.id,
                            )
                            .filter((response) =>
                                filteredInterviews
                                    .map((interview) => interview.id)
                                    .includes(response.interview),
                            ),
                        (response) => response.options,
                    );
                    const answersByOptionId = groupBy(
                        allOptionAnswers,
                        (answer) => answer.option,
                    );
                    const sortedOptions = reverse(
                        sortBy(
                            question.options,
                            (option) =>
                                answersByOptionId[assertNonNull(option.id)]
                                    ?.length ?? 0,
                        ),
                    );
                    return (
                        <div className="card" key={question.id}>
                            <h3 className="text-xl font-semibold mb-8">
                                {question.text}
                            </h3>

                            <div className="flex flex-col">
                                {sortedOptions.map((questionOption) => {
                                    const responseCount =
                                        answersByOptionId[
                                            assertNonNull(questionOption.id)
                                        ]?.length ?? 0;
                                    const percentage = getPercentage(
                                        responseCount,
                                        allOptionAnswers.length,
                                    );
                                    return (
                                        <div
                                            className="flex flex-row mb-4"
                                            key={questionOption.id}
                                        >
                                            <div className="flex flex-row flex-1 text-sm pr-4">
                                                <div className="flex-1">
                                                    <strong className="font-medium mr-2">
                                                        {questionOption.text}
                                                    </strong>
                                                    ({responseCount} answers)
                                                </div>
                                                <Link
                                                    to={questionPageUrl(
                                                        projectId,
                                                        assertNonNull(
                                                            question.id,
                                                        ),
                                                    )}
                                                >
                                                    Details
                                                    {"->"}
                                                </Link>
                                                <div className="flex flex-col px-2 text-center w-[10ch]">
                                                    ({percentage}
                                                    %)
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 justify-center">
                                                <div className="flex w-full h-3 rounded-lg bg-gray-200">
                                                    <div
                                                        className="flex h-3 rounded-lg bg-gp-blue-light"
                                                        style={{
                                                            width: `${percentage}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default QuestionsExplorer;
