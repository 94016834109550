import { useCallback, useEffect, useMemo, useState } from "react";
import { unstable_usePrompt, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
    checkCeleryTask,
    deleteQuote,
    parseCompetitorQuotes,
    saveQuote,
} from "../api";
import Button, { ButtonVariant } from "../components/button";
import Icon from "../components/icon";
import Loader from "../components/loader";
import InterviewParagraphs from "../components/parser/interview-paragraphs";
import InterviewSelector from "../components/parser/interview-selector";
import PromptOverrideModal from "../components/parser/prompt-override-modal";
import QuoteCard from "../components/parser/quote-card";
import ScrollShadowWrapper from "../components/scroll-shadow-wrapper";
import UnsavedCounter from "../components/unsaved-counter";
import { useAppDispatch, useAppSelector, useCheckWhenLeaving } from "../hooks";
import { setQuotes } from "../stores/project";
import {
    PromptType,
    Quote,
    QuoteTopicType,
    Sentiment,
    WithUUID,
    emptyQuote,
} from "../types/goldpan";
import { Messages } from "./message-list";

type AiQuote = {
    sentiment: Sentiment;
    competitor_id: number | null;
    topic_type: QuoteTopicType;
    paragraph_quote: string;
    paragraph_id: string;
};

const validateQuote = (quote: Quote) => {
    let isValid = true;
    if (!quote.text) {
        isValid = false;
        Messages.error("Quote missing text");
    }
    if (
        quote.topic_type &&
        quote.topic_type === QuoteTopicType.COMPETITOR &&
        !quote.competitor
    ) {
        isValid = false;
        Messages.error("Competitor quote needs a competitor defined");
    }
    return isValid;
};

const ParseQuotesApp: React.FC = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const dispatch = useAppDispatch();
    const [isLoadingInterview, setIsLoadingInterview] = useState(false);
    const [isParsingQuotes, setIsParsingQuotes] = useState(false);
    const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
    const competitors = useAppSelector((state) => state.project.competitors);
    const allQuotes = useAppSelector((state) => state.project.quotes);
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewId = useAppSelector(
        (state) => state.interviewParser.interviewId,
    );
    const [interviewQuotes, setInterviewQuotes] = useState<WithUUID<Quote>[]>(
        [],
    );

    const dirtyCount = useMemo(
        () => interviewQuotes.filter((quote) => quote.isDirty).length,
        [interviewQuotes],
    );
    const shouldCheckBeforeLeavingPage = dirtyCount > 0;
    useCheckWhenLeaving(shouldCheckBeforeLeavingPage);
    unstable_usePrompt({
        message: "You have unsaved changes. Are you sure you want to leave?",
        when: ({ currentLocation, nextLocation }) =>
            shouldCheckBeforeLeavingPage &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    useEffect(() => {
        setInterviewQuotes([]);
        if (!interviewId) {
            return;
        }
        setIsLoadingInterview(true);
        setInterviewQuotes(
            allQuotes
                .filter(
                    (quote) => quote.interview === interviewId && quote.hanging,
                )
                .map((quote) => ({
                    data: quote,
                    isDirty: false,
                    uuid: uuidv4(),
                })),
        );
        setIsLoadingInterview(false);

        // don't include allQuotes here otherwise re-render unnecessarily
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interviewId, projectId]);

    const interview = useMemo(
        () => interviews.find((i) => i.id === interviewId) ?? null,
        [interviews, interviewId],
    );

    const updateQuote = useCallback(
        (quote: Partial<WithUUID<Quote>>) => {
            const newQuotes = interviewQuotes.map((q) => {
                if (q.uuid !== quote.uuid) {
                    return q;
                }
                return { ...q, ...quote };
            });
            setInterviewQuotes(newQuotes);
        },
        [interviewQuotes],
    );

    const handleParseQuotes = useCallback(async () => {
        if (!interviewId) {
            return;
        }
        setIsParsingQuotes(true);
        const response = await parseCompetitorQuotes(projectId, interviewId);
        if (response) {
            checkCeleryTask<AiQuote[]>(
                response.data.task_id,
                (response) =>
                    setInterviewQuotes([
                        ...interviewQuotes,
                        ...response.map((quote) => ({
                            data: {
                                ...emptyQuote(interviewId),
                                id: null,
                                text: quote.paragraph_quote,
                                interview: interviewId,
                                subtheme: null,
                                interview_paragraph: interview!.paragraphs.find(
                                    (p) =>
                                        p.ordinal ===
                                        parseInt(quote.paragraph_id),
                                )!.id,
                                competitor: quote.competitor_id,
                                sentiment: quote.sentiment,
                                topic_type: quote.topic_type,
                            },
                            isDirty: true,
                            uuid: uuidv4(),
                        })),
                    ]),
                () => setIsParsingQuotes(false),
            );
        } else {
            setIsParsingQuotes(false);
        }
    }, [interview, projectId, interviewId, interviewQuotes]);

    const handleAddNewQuote = useCallback(() => {
        if (!interviewId) {
            return;
        }
        const newQuotes = [
            {
                isDirty: true,
                uuid: uuidv4(),
                data: emptyQuote(interviewId),
            },
            ...interviewQuotes,
        ];
        setInterviewQuotes(newQuotes);
    }, [interviewId, interviewQuotes]);

    const handleSaveQuote = useCallback(
        async (quoteUuid: string) => {
            Messages.removeAll();
            const quote = interviewQuotes.find((r) => r.uuid === quoteUuid);
            const isNew =
                quote?.data.id === null || quote?.data.id === undefined;
            if (interviewId && quote && validateQuote(quote.data)) {
                const response = await saveQuote(projectId, quote.data);
                if (!response) {
                    return;
                }
                updateQuote({
                    data: response.data,
                    uuid: quoteUuid,
                    isDirty: false,
                });
                if (isNew) {
                    dispatch(setQuotes(allQuotes.concat([response.data])));
                } else {
                    dispatch(
                        setQuotes(
                            allQuotes.map((q) => {
                                if (q.id === response.data.id) {
                                    return response.data;
                                }
                                return q;
                            }),
                        ),
                    );
                }
                Messages.success("Saved successfully");
            }
        },
        [
            interviewQuotes,
            interviewId,
            projectId,
            updateQuote,
            dispatch,
            allQuotes,
        ],
    );
    const handleDeleteQuote = useCallback(
        async (quoteUuid: string) => {
            Messages.removeAll();
            const quote = interviewQuotes.find((r) => r.uuid === quoteUuid);
            if (interviewId && quote) {
                if (quote.data.id !== null) {
                    const response = await deleteQuote(projectId, quote.data);
                    if (!response) {
                        return;
                    }
                }
                setInterviewQuotes(
                    interviewQuotes.filter((r) => r.uuid !== quoteUuid),
                );
                dispatch(
                    setQuotes(allQuotes.filter((q) => q.id !== quote.data.id)),
                );
                Messages.success("Quote deleted");
            }
        },
        [interviewQuotes, interviewId, dispatch, allQuotes, projectId],
    );

    const paragraphOptions = useMemo(
        () =>
            interview?.paragraphs.map((paragraph) => ({
                value: paragraph.id,
                label: `Paragraph ${paragraph.ordinal}`,
            })) ?? [],
        [interview],
    );

    return (
        <>
            <div className="w-full mt-4 mb-8 flex items-center justify-between">
                <div className="flex items-center">
                    <div className="w-[500px]">
                        <InterviewSelector
                            disabled={isParsingQuotes}
                            interviews={interviews}
                            selectedInterviewId={interviewId}
                        />
                    </div>
                    {interviewId !== null && (
                        <>
                            {isOverrideModalOpen && (
                                <PromptOverrideModal
                                    projectId={projectId}
                                    promptTypes={[
                                        PromptType.COLLECT_COMPETITOR_QUOTES,
                                        PromptType.COLLECT_CLIENT_QUOTES,
                                    ]}
                                    onClose={() =>
                                        setIsOverrideModalOpen(false)
                                    }
                                />
                            )}
                            <div className="flex items-center gap-2">
                                <Button
                                    className="ml-4"
                                    id="parse-quotes-button"
                                    isLoading={isParsingQuotes}
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={handleParseQuotes}
                                >
                                    Parse competitor quotes
                                </Button>
                                <Button
                                    className="no-bg"
                                    tooltip="AI prompt settings"
                                    onClick={() => setIsOverrideModalOpen(true)}
                                >
                                    <Icon icon="settings" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {interviewId !== null && (
                    <UnsavedCounter dirtyCount={dirtyCount} />
                )}
            </div>

            {isLoadingInterview && (
                <div className="mt-8">
                    <Loader />
                </div>
            )}

            {interview !== null && !isLoadingInterview && (
                <div className="w-full flex flex-grow gap-2 ai-parser-height">
                    <div className="w-3/5 flex flex-col">
                        <div className="flex items-center mb-4">
                            <h3>Quotes</h3>
                            <Button
                                className="ml-4 text-sm"
                                disabled={isParsingQuotes}
                                icon="add"
                                onClick={handleAddNewQuote}
                            >
                                Add new
                            </Button>
                        </div>
                        <ScrollShadowWrapper>
                            {interviewQuotes.map((quote) => (
                                <QuoteCard
                                    competitors={competitors}
                                    interviewParagraphs={interview.paragraphs}
                                    key={quote.uuid}
                                    paragraphOptions={paragraphOptions}
                                    quote={quote}
                                    onDeleteQuote={handleDeleteQuote}
                                    onSaveQuote={handleSaveQuote}
                                    onUpdateQuote={updateQuote}
                                />
                            ))}
                        </ScrollShadowWrapper>
                    </div>
                    <div className="w-2/5 flex flex-col">
                        <h3 className="mb-4">Transcript</h3>
                        <ScrollShadowWrapper>
                            <InterviewParagraphs
                                paragraphs={interview.paragraphs}
                            />
                        </ScrollShadowWrapper>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParseQuotesApp;
