import classNames from "classnames";
import { compact } from "lodash";
import { PropsWithChildren, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { releaseHijack } from "../../api";
import { useAppSelector } from "../../hooks";
import {
    decisionsExplorerUrl,
    questionExplorerUrl,
    quoteExplorerUrl,
} from "../../urls";
import Button, { ButtonVariant } from "../button";
import Icon, { Icons } from "../icon";

const SideNav = ({
    children,
    hideProject,
}: PropsWithChildren<{ hideProject?: boolean }>) => {
    const project = useAppSelector((state) => state.project.project);
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const adminActions: { to: string; icon: Icons; text: string }[] = useMemo(
        () =>
            project
                ? compact([
                      window.goldpanUser?.permissions.includes(
                          "core.use_interview_ai_parser",
                      ) &&
                          window.waffle.flag_is_active(
                              "transcript-o-matic",
                          ) && {
                              to: `/project/${project.id}/transcript-o-matic/`,
                              icon: "transcript",
                              text: "Load Interview",
                          },
                      window.goldpanUser?.permissions.includes(
                          "core.use_interview_ai_parser",
                      ) && {
                          to: `/project/${project.id}/interview-parser/`,
                          icon: "beaker",
                          text: "AI Interview Parser",
                      },
                      window.goldpanUser?.permissions.includes(
                          "integrations.view_hubspotintegration",
                      ) &&
                          project.has_hubspot_integration && {
                              to: `/project/${project.id}/integrations/hubspot/`,
                              icon: "list_alt",
                              text: "Hubspot",
                          },
                      window.goldpanUser?.permissions.includes(
                          "core.project_data_cleanup",
                      ) && {
                          to: `/project/${project.id}/settings/`,
                          icon: "settings",
                          text: "Project Settings",
                      },
                  ])
                : [],
        [project],
    );

    return (
        <div className="flex w-full flex-row min-h-screen relative">
            <nav
                className={classNames(
                    "fixed h-screen left-0 lg:flex w-72 flex-col bg-gp-blue text-white justify-between",
                    { "flex z-50": isSidebarOpen, hidden: !isSidebarOpen },
                )}
            >
                <div>
                    <NavLink to="/">
                        <img
                            alt="Goldpan"
                            className="ml-8 mt-4 py-2 max-w-[120px]"
                            src={`${window.STATIC_URL}images/logos/GoldpanLightLogo.svg`}
                        />
                    </NavLink>

                    {project && !hideProject && (
                        <>
                            <div className="mt-4 px-8 py-6 bg-gp-blue-dark">
                                <div className="font-bold">{project.title}</div>
                                <div className="text-sm">
                                    {project.client_name}
                                </div>
                            </div>

                            <div className="flex flex-col px-2 pt-4 lg:px-4 font-medium">
                                <Link
                                    className="sidebar-link flex flex-row items-center"
                                    to={`/project/${project.id}/`}
                                >
                                    <Icon className="text-lg" icon="home" />
                                    <span className="ml-2">Project Home</span>
                                </Link>
                                <NavLink
                                    className={({ isActive }) =>
                                        classNames(
                                            "sidebar-link flex flex-row items-center",
                                            { active: isActive },
                                        )
                                    }
                                    to={`/project/${project.id}/interview/`}
                                >
                                    <Icon className="text-lg" icon="chat" />
                                    <span className="ml-2">Interviews</span>
                                </NavLink>

                                {window.waffle.flag_is_active("home-v2") ? (
                                    <>
                                        <div className="my-2 pl-4 text-white opacity-25 text-sm">
                                            INSIGHTS
                                        </div>
                                        {questionGroups.map((group) => (
                                            <NavLink
                                                className={({ isActive }) =>
                                                    classNames("sidebar-link", {
                                                        active: isActive,
                                                    })
                                                }
                                                key={group.title}
                                                to={`/project/${project.id}/insights/${group.slug}/`}
                                            >
                                                <span className="ml-2">
                                                    {group.title}
                                                </span>
                                            </NavLink>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <NavLink
                                            className={({ isActive }) =>
                                                classNames(
                                                    "sidebar-link flex flex-row items-center",
                                                    { active: isActive },
                                                )
                                            }
                                            to={decisionsExplorerUrl(
                                                project.id,
                                            )}
                                        >
                                            <Icon
                                                className="text-lg"
                                                icon="decision"
                                            />
                                            <span className="ml-2">
                                                Decision Reasons
                                            </span>
                                        </NavLink>

                                        <NavLink
                                            className={({ isActive }) =>
                                                classNames(
                                                    "sidebar-link flex flex-row items-center",
                                                    { active: isActive },
                                                )
                                            }
                                            to={questionExplorerUrl(project.id)}
                                        >
                                            <Icon
                                                className="text-lg"
                                                icon="question"
                                            />
                                            <span className="ml-2">
                                                Questions
                                            </span>
                                        </NavLink>

                                        <NavLink
                                            className={({ isActive }) =>
                                                classNames(
                                                    "sidebar-link flex flex-row items-center",
                                                    { active: isActive },
                                                )
                                            }
                                            to={quoteExplorerUrl(project.id)}
                                        >
                                            <Icon
                                                className="text-lg"
                                                icon="quote"
                                            />
                                            <span className="ml-2">Quotes</span>
                                        </NavLink>
                                    </>
                                )}

                                {adminActions.length > 0 && (
                                    <>
                                        <div className="my-2 pl-4 text-white opacity-25 text-sm">
                                            ADMIN
                                        </div>
                                        {adminActions.map((action) => (
                                            <NavLink
                                                className={({ isActive }) =>
                                                    classNames(
                                                        "sidebar-link flex flex-row items-center",
                                                        { active: isActive },
                                                    )
                                                }
                                                key={action.text}
                                                to={action.to}
                                            >
                                                <Icon
                                                    className="text-lg"
                                                    icon={action.icon}
                                                />
                                                <span className="ml-2">
                                                    {action.text}
                                                </span>
                                            </NavLink>
                                        ))}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {window.goldpanUser && (
                    <>
                        <div className="flex flex-col px-2 pt-4 lg:px-4 font-medium">
                            {window.goldpanUser.is_staff && (
                                <>
                                    <a className="sidebar-link" href="/admin/">
                                        Django Admin
                                    </a>
                                    <a
                                        className="sidebar-link"
                                        href="/conversations/"
                                        target="_blank"
                                    >
                                        Conversations (Alpha)
                                    </a>
                                </>
                            )}
                            {window.goldpanUser.is_hijacked && (
                                <button
                                    className="sidebar-link"
                                    onClick={() => {
                                        releaseHijack().then(() =>
                                            window.location.reload(),
                                        );
                                    }}
                                >
                                    End demo mode
                                </button>
                            )}
                            <a className="sidebar-link" href="/logout/">
                                Logout
                            </a>
                            <div className="p-4 font-medium">
                                <div className="text-ellipsis text-sm overflow-hidden">
                                    {window.goldpanUser.email}
                                </div>
                                {window.goldpanUser.is_hijacked && (
                                    <div className="mt-1 text-xs text-gray-300 text-ellipsis overflow-hidden">
                                        (Demo mode)
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </nav>

            <Button
                className="absolute top-4 right-4 lg:hidden"
                variant={ButtonVariant.PRIMARY}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <Icon icon="menu" />
            </Button>

            <main className="h-screen w-full lg:ml-72">{children}</main>
        </div>
    );
};

export default SideNav;
