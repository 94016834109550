import classNames from "classnames";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";

import { Comparison, ComparisonType } from "../../comparisons";
import {
    CompetitorFilter,
    Filter,
    FilterType,
    InterviewMetadataFilter,
    OutcomeFilter,
} from "../../filters";
import { useAppSelector } from "../../hooks";
import Button, { ButtonVariant } from "../button";
import Icon from "../icon";
import CompetitorSelect from "./competitor-select";
import MetadataValueSelect from "./metadata-value-select";
import OutcomeSelect from "./outcome-select";

const isFilterEqual = (filter1: Filter, filter2: Filter) => {
    switch (filter1.type) {
        case FilterType.InterviewMetadata:
            return (
                filter1.type === filter2.type &&
                filter1.field &&
                filter1.field === filter2.field
            );
        default:
            return filter1.type === filter2.type;
    }
};

const FilterItem = ({
    children,
    disabled,
    filter,
    filterKey,
    onToggle,
    openFilters,
    title,
}: PropsWithChildren<{
    filter?: Filter;
    disabled: boolean;
    filterKey: string;
    title: string;
    openFilters: string[];
    onToggle: (key: string) => void;
}>) => {
    return (
        <>
            <div
                className={classNames(
                    "border-t p-4 flex items-center justify-between hover:bg-gray-50",
                    { "bg-gray-50 hover:cursor-not-allowed": disabled },
                    { "hover:cursor-pointer": !disabled },
                )}
                role="button"
                onClick={() => !disabled && onToggle(filterKey)}
            >
                <div className="flex gap-1 items-center text-sm">
                    <div className="font-medium">{title}</div>
                    {filter && filter.values.length > 0 && (
                        <div className="px-2 text-gray-400 rounded-full border">
                            {filter.values.length}
                        </div>
                    )}
                </div>
                <Icon
                    className="material-icons text-gray-500"
                    icon={
                        openFilters.includes(filterKey)
                            ? "expand_less"
                            : "expand_more"
                    }
                />
            </div>
            {openFilters.includes(filterKey) && !disabled && (
                <div className="p-4 bg-gray-100">{children}</div>
            )}
        </>
    );
};

const FilterSidebar = ({
    activeComparison = [],
    activeFilters,
    hideCompetitors,
    onChangeFilters,
}: {
    activeComparison?: Comparison[];
    activeFilters: Filter[];
    hideCompetitors?: boolean;
    onChangeFilters: (filters: Filter[]) => void;
}) => {
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const competitors = useAppSelector((state) => state.project.competitors);
    const [openFilters, setOpenFilters] = useState<string[]>([]);

    const toggleFilterGroup = useCallback(
        (filterName: string) => {
            if (openFilters.includes(filterName)) {
                setOpenFilters(
                    openFilters.filter((filter) => filter !== filterName),
                );
            } else {
                setOpenFilters([...openFilters, filterName]);
            }
        },
        [openFilters],
    );

    const outcomeFilter: OutcomeFilter | null = useMemo(() => {
        return (
            (activeFilters.find(
                (f) => f.type === FilterType.Outcome,
            ) as OutcomeFilter) ?? null
        );
    }, [activeFilters]);
    const competitorFilter: CompetitorFilter | null = useMemo(() => {
        return (
            (activeFilters.find(
                (f) => f.type === FilterType.Competitor,
            ) as CompetitorFilter) ?? null
        );
    }, [activeFilters]);

    const handleUpdateFilters = useCallback(
        (filter: Filter) => {
            const existing = activeFilters.find((f) =>
                isFilterEqual(filter, f),
            );
            if (existing && filter.values.length > 0) {
                onChangeFilters(
                    activeFilters.map((f) => {
                        if (isFilterEqual(filter, f)) {
                            return filter;
                        }
                        return f;
                    }),
                );
            } else if (existing && filter.values.length === 0) {
                onChangeFilters(
                    activeFilters.filter((f) => !isFilterEqual(filter, f)),
                );
            } else if (filter.values.length > 0) {
                onChangeFilters([...activeFilters, filter]);
            }
        },
        [activeFilters, onChangeFilters],
    );

    const validFilterCount = useMemo(
        () =>
            activeFilters.filter(
                (filter) =>
                    filter.type !== FilterType.Competitor || !hideCompetitors,
            ).length,
        [activeFilters, hideCompetitors],
    );

    return (
        <div className="bg-white shadow rounded-lg place-self-start w-full">
            <div className="p-4 h-16 flex items-center justify-between">
                <div className="font-bold">Filters</div>
                {validFilterCount > 0 && (
                    <div className="flex gap-2 items-center">
                        <div className="text-gray-500 italic text-sm">
                            {validFilterCount}{" "}
                            {validFilterCount === 1 ? "filter" : "filters"}{" "}
                            active
                        </div>
                        <Button
                            className="no-bg icon-only clear-all-filters"
                            icon="close"
                            tooltip="Clear all filters"
                            variant={ButtonVariant.DANGER}
                            onClick={() => onChangeFilters([])}
                        />
                    </div>
                )}
            </div>

            <FilterItem
                disabled={
                    !!activeComparison.find(
                        (comparison) =>
                            comparison.type === ComparisonType.OUTCOMES,
                    )
                }
                filter={outcomeFilter}
                filterKey={FilterType.Outcome}
                openFilters={openFilters}
                title="Outcomes"
                onToggle={toggleFilterGroup}
            >
                <OutcomeSelect
                    className="filter-select"
                    selected={outcomeFilter ? outcomeFilter.values : []}
                    onChange={(selectedOutcomeIds) =>
                        handleUpdateFilters({
                            type: FilterType.Outcome,
                            values: selectedOutcomeIds,
                        })
                    }
                />
            </FilterItem>

            {interviewMetadataFields.map((field) => {
                const filter =
                    (activeFilters.find(
                        (f) =>
                            f.type === FilterType.InterviewMetadata &&
                            f.field === field.id,
                    ) as InterviewMetadataFilter) ?? null;

                return (
                    <FilterItem
                        disabled={
                            !!activeComparison.find(
                                (comparison) =>
                                    comparison.type ===
                                        ComparisonType.METADATA &&
                                    comparison.fieldId === field.id,
                            )
                        }
                        filter={filter}
                        filterKey={`${field.id}${FilterType.InterviewMetadata}`}
                        key={field.id}
                        openFilters={openFilters}
                        title={field.display_title}
                        onToggle={toggleFilterGroup}
                    >
                        <MetadataValueSelect
                            className="filter-select"
                            fieldId={field.id}
                            selected={filter ? filter.values : []}
                            onChange={(selectedMetadataValues) =>
                                handleUpdateFilters({
                                    type: FilterType.InterviewMetadata,
                                    field: field.id,
                                    values: selectedMetadataValues,
                                })
                            }
                        />
                    </FilterItem>
                );
            })}

            {competitors.length > 0 && !hideCompetitors && (
                <FilterItem
                    disabled={false}
                    filter={competitorFilter}
                    filterKey={FilterType.Competitor}
                    openFilters={openFilters}
                    title="Competitors"
                    onToggle={toggleFilterGroup}
                >
                    <CompetitorSelect
                        selected={
                            competitorFilter ? competitorFilter.values : []
                        }
                        onChange={(selectedCompetitorIds) =>
                            handleUpdateFilters({
                                type: FilterType.Competitor,
                                values: selectedCompetitorIds,
                            })
                        }
                    />
                </FilterItem>
            )}
        </div>
    );
};

export default FilterSidebar;
