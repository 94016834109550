import { compact } from "lodash";
import { useEffect } from "react";
import {
    Outlet,
    RouterProvider,
    ScrollRestoration,
    createBrowserRouter,
    useParams,
} from "react-router-dom";

import { getProjectAllData } from "../api";
import CreateProjectApp from "../apps/create-project";
import DecisionsExplorer from "../apps/decisions-explorer";
import HubspotIntegration from "../apps/hubspot-integration";
import InterviewList from "../apps/interview-list";
import InterviewSummary from "../apps/interview-summary";
import InterviewTable from "../apps/interview-table";
import ParseDecisionsApp from "../apps/parse-decisions";
import ParseInterviewMetadata from "../apps/parse-interview-metadata";
import ParseQuestionsApp from "../apps/parse-questions";
import ParseQuotesApp from "../apps/parse-quotes";
import ParseTranscriptApp from "../apps/parse-transcript";
import ProjectSelector from "../apps/project-selector";
import ProjectSettingsContainer from "../apps/project-settings-container";
import ProjectSummaryApp from "../apps/project-summary";
import ProjectSummaryAppV2 from "../apps/project-summary-v2";
import QuestionGroup from "../apps/question-group";
import QuestionPage from "../apps/question-page";
import QuestionsExplorer from "../apps/questions-explorer";
import QuoteExplorer from "../apps/quote-explorer";
import ThemeExplorer from "../apps/theme-explorer";
import TranscriptOMatic from "../apps/transcript-o-matic";
import Loader from "../components/loader";
import PageTitle from "../components/page-title";
import InterviewParserContainer from "../components/parser/interview-parser-container";
import SideNav from "../components/sidenav/sidenav";
import { urlParamsToFilters, useAppDispatch, useAppSelector } from "../hooks";
import {
    resetProject,
    setAiPrompts,
    setCompetitors,
    setDecisionReasons,
    setFilters,
    setInterviewMetadataFields,
    setInterviews,
    setIsProjectLoading,
    setOutcomes,
    setProject,
    setProjectQuestionSummaries,
    setQuestionGroups,
    setQuestionResponseSummaries,
    setQuestions,
    setQuotes,
    setSubthemes,
} from "../stores/project";
import { assertNonNull } from "../utils";

export const PageWithHeader = ({
    actions,
    children,
    heading,
}: React.PropsWithChildren<{ heading: string; actions?: JSX.Element }>) => {
    const project = useAppSelector((state) => state.project.project);

    return (
        <>
            <PageTitle title={assertNonNull(project).title} />
            <header className="flex flex-col pb-5 border-b-4 border-black mb-8">
                <p className="font-semibold text-indigo-900">
                    {assertNonNull(project).title}
                </p>
                <div className="flex justify-between items-center">
                    <h1 className="text-5xl font-bold">{heading}</h1>
                    {actions}
                </div>
            </header>
            {children}
        </>
    );
};

const ProjectPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const isProjectLoading = useAppSelector(
        (state) => state.project.isProjectLoading,
    );
    const project = useAppSelector((state) => state.project.project);

    useEffect(() => {
        dispatch(setIsProjectLoading(true));
        dispatch(resetProject());
        getProjectAllData(projectId)
            .then((allData) => {
                if (!allData) {
                    return;
                }

                dispatch(setProject(allData.data.project));
                dispatch(setInterviews(allData.data.interviews));
                dispatch(
                    setInterviewMetadataFields(
                        allData.data.interview_metadata_fields,
                    ),
                );
                dispatch(setQuotes(allData.data.quotes));
                dispatch(setSubthemes(allData.data.subthemes));
                dispatch(setCompetitors(allData.data.competitors));
                dispatch(setOutcomes(allData.data.outcomes));
                dispatch(setQuestions(allData.data.questions));
                dispatch(setQuestionGroups(allData.data.question_groups));
                dispatch(
                    setQuestionResponseSummaries(
                        allData.data.response_summaries,
                    ),
                );
                dispatch(setAiPrompts(allData.data.prompts));
                dispatch(setDecisionReasons(allData.data.decision_reasons));
                dispatch(
                    setProjectQuestionSummaries(
                        allData.data.project_question_summaries,
                    ),
                );

                const filters = urlParamsToFilters(
                    new URLSearchParams(location.search),
                );
                dispatch(setFilters(filters));
            })
            .finally(() => dispatch(setIsProjectLoading(false)));
    }, [dispatch, projectId]);

    if (isProjectLoading) {
        return (
            <div className="mt-16">
                <Loader />
            </div>
        );
    }

    if (!project) {
        return (
            <SideNav>
                <div className="mt-16 text-center">Project not found</div>
            </SideNav>
        );
    }

    return (
        <SideNav>
            <div className="w-full flex flex-col px-12 py-8">
                <Outlet />
            </div>
        </SideNav>
    );
};

const newTranscriptParser = window.waffle.flag_is_active("transcript-o-matic");
const router = createBrowserRouter([
    {
        path: "*",
        Component: () => (
            <>
                <ScrollRestoration getKey={(location) => location.pathname} />
                <Outlet />
            </>
        ),
        children: compact([
            {
                path: "*",
                status: 404,
                element: (
                    <SideNav hideProject>
                        <div className="w-full h-full flex flex-col px-12 py-8">
                            Page not found
                        </div>
                    </SideNav>
                ),
            },
            {
                index: true,
                element: (
                    <SideNav hideProject>
                        <PageTitle title="Home" />
                        <div className="w-full h-full flex flex-col px-12 py-8">
                            <ProjectSelector />
                        </div>
                    </SideNav>
                ),
            },
            window.goldpanUser?.permissions.includes("core.add_project") && {
                path: "create-project/",
                element: (
                    <SideNav hideProject>
                        <PageTitle title="Create project" />
                        <div className="w-full h-full flex flex-col px-12 py-8">
                            <h1 className="text-5xl font-bold pb-5 border-b-4 border-black mb-8">
                                Create project
                            </h1>
                            <CreateProjectApp />
                        </div>
                    </SideNav>
                ),
            },
            {
                path: "project/:projectId/",
                Component: ProjectPage,
                children: compact([
                    {
                        index: true,
                        element: (
                            <PageWithHeader heading="Home">
                                {window.waffle.flag_is_active("home-v2") ? (
                                    <ProjectSummaryAppV2 />
                                ) : (
                                    <ProjectSummaryApp />
                                )}
                            </PageWithHeader>
                        ),
                    },
                    {
                        path: "interview/",
                        element: (
                            <PageWithHeader heading="All interviews">
                                {window.waffle.flag_is_active("home-v2") ? (
                                    <InterviewList />
                                ) : (
                                    <InterviewTable />
                                )}
                            </PageWithHeader>
                        ),
                    },
                    {
                        path: "interview/:interviewSlug/",
                        element: <InterviewSummary />,
                    },
                    {
                        path: "insights/:questionGroupSlug/",
                        element: <QuestionGroup />,
                    },
                    !window.waffle.flag_is_active("home-v2") && {
                        path: "decision-reasons/",
                        element: (
                            <PageWithHeader heading="Decision Reasons">
                                <DecisionsExplorer />
                            </PageWithHeader>
                        ),
                    },
                    !window.waffle.flag_is_active("home-v2") && {
                        path: "questions/",
                        element: (
                            <PageWithHeader heading="Questions">
                                <QuestionsExplorer />
                            </PageWithHeader>
                        ),
                    },
                    !window.waffle.flag_is_active("home-v2") && {
                        path: "questions/:questionId/",
                        element: <QuestionPage />,
                    },
                    !window.waffle.flag_is_active("home-v2") && {
                        path: "quotes/",
                        element: (
                            <PageWithHeader heading="Quotes">
                                <QuoteExplorer />
                            </PageWithHeader>
                        ),
                    },
                    !window.waffle.flag_is_active("home-v2") && {
                        path: "theme/:themeId/",
                        element: <ThemeExplorer />,
                    },
                    window.goldpanUser?.permissions.includes(
                        "core.use_interview_ai_parser",
                    ) && {
                        path: "interview-parser/",
                        Component: () => (
                            <PageWithHeader heading="AI Interview Parser">
                                <Outlet />
                            </PageWithHeader>
                        ),
                        children: compact([
                            !newTranscriptParser && {
                                index: true,
                                element: (
                                    <InterviewParserContainer activeStep={0}>
                                        <ParseTranscriptApp />
                                    </InterviewParserContainer>
                                ),
                            },
                            {
                                index: newTranscriptParser,
                                path: newTranscriptParser
                                    ? undefined
                                    : "metadata",
                                element: (
                                    <InterviewParserContainer
                                        activeStep={newTranscriptParser ? 0 : 1}
                                    >
                                        <ParseInterviewMetadata />
                                    </InterviewParserContainer>
                                ),
                            },
                            {
                                path: "decisions",
                                element: (
                                    <InterviewParserContainer
                                        activeStep={newTranscriptParser ? 1 : 2}
                                    >
                                        <ParseDecisionsApp />
                                    </InterviewParserContainer>
                                ),
                            },
                            {
                                path: "questions",
                                element: (
                                    <InterviewParserContainer
                                        activeStep={newTranscriptParser ? 2 : 3}
                                    >
                                        <ParseQuestionsApp />
                                    </InterviewParserContainer>
                                ),
                            },
                            {
                                path: "quotes",
                                element: (
                                    <InterviewParserContainer
                                        activeStep={newTranscriptParser ? 3 : 4}
                                    >
                                        <ParseQuotesApp />
                                    </InterviewParserContainer>
                                ),
                            },
                        ]),
                    },
                    window.goldpanUser?.permissions.includes(
                        "core.use_interview_ai_parser",
                    ) && {
                        path: "transcript-o-matic/",
                        Component: () => (
                            <PageWithHeader heading="Load Interview">
                                <TranscriptOMatic />
                            </PageWithHeader>
                        ),
                    },
                    window.goldpanUser?.permissions.includes(
                        "core.project_data_cleanup",
                    ) && {
                        path: "settings/",
                        Component: () => (
                            <PageWithHeader heading="Project Settings">
                                <ProjectSettingsContainer />
                            </PageWithHeader>
                        ),
                    },
                    window.goldpanUser?.permissions.includes(
                        "integrations.view_hubspotintegration",
                    ) && {
                        path: "integrations/hubspot/",
                        element: <HubspotIntegration />,
                    },
                ]),
            },
        ]),
    },
]);

const AppRouter: React.FC = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
