import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition,
} from "@headlessui/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "../hooks";
import { Interview } from "../types/goldpan";
import { interviewUrl } from "../urls";
import { assertNonNull } from "../utils";
import Icon from "./icon";

const InterviewPopover = ({
    className,
    interview,
}: {
    interview: Interview;
    className?: string;
}) => {
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const interviewMetadataFields = assertNonNull(
        useAppSelector((state) => state.project.interviewMetadataFields),
    );
    const [isShowing, setIsShowing] = useState(false);
    return (
        <Popover>
            <PopoverButton>
                {() => (
                    <Link
                        className={className}
                        to={interviewUrl(project.id, interview.slug)}
                        onMouseEnter={() => setIsShowing(true)}
                        onMouseLeave={() => setIsShowing(false)}
                    >
                        {interview.name}
                    </Link>
                )}
            </PopoverButton>
            <Transition
                enter="duration-200 ease-out"
                enterFrom="scale-95 opacity-0"
                enterTo="scale-100 opacity-100"
                leave="duration-200 ease-out"
                leaveFrom="scale-100 opacity-100"
                leaveTo="scale-95 opacity-0"
                show={isShowing}
            >
                <PopoverPanel
                    anchor={{ to: "top", gap: 10, offset: 20 }}
                    className="flex origin-bottom flex-col transition bg-white p-4 rounded-md shadow-lg border"
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <Icon
                                className="h-6 w-6 mr-2 text-gray-400"
                                icon="chat"
                            />
                            <div className="uppercase text-gray-400 font-bold text-sm">
                                Interview
                            </div>
                        </div>
                        <div className="font-bold text-lg ml-16">
                            {interview.name}
                        </div>
                    </div>
                    <div className="text-sm text-right italic mb-4 pb-2 border-b">
                        {dayjs(interview.date).format("MMM D, YYYY")}
                    </div>
                    <div className="text-sm flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-4">
                            <div
                                className={classNames("font-bold", {
                                    "text-gp-accent":
                                        !project.main_interview_field,
                                })}
                            >
                                Outcome:
                            </div>
                            <div>{interview.outcome?.name ?? "-"}</div>
                        </div>
                        {interviewMetadataFields.map((field) => {
                            const data = interview.metadata_list.find(
                                (f) => f.interview_field.id === field.id,
                            );
                            return (
                                <div
                                    className="flex items-center justify-between gap-4"
                                    key={field.id}
                                >
                                    <div
                                        className={classNames("font-bold", {
                                            "text-gp-accent":
                                                project.main_interview_field ===
                                                field.id,
                                        })}
                                    >
                                        {field.display_title}:
                                    </div>
                                    <div>
                                        {data ? (
                                            <span>{data.data}</span>
                                        ) : (
                                            <span>(blank)</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </PopoverPanel>
            </Transition>
        </Popover>
    );
};

export default InterviewPopover;
