import classNames from "classnames";

interface Props
    extends Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "type"> {
    onChange: (checked: boolean) => void;
    checkboxClassName?: string;
    labelClassName?: string;
}

const Checkbox: React.FC<Props> = ({
    checkboxClassName,
    checked,
    className,
    disabled,
    label,
    labelClassName,
    onChange,
    ...rest
}) => {
    return (
        <div className={classNames("flex items-center", className)}>
            <input
                checked={checked}
                className={classNames(
                    "w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2",
                    {
                        "bg-gray-100 hover:cursor-pointer": !disabled,
                        "bg-gray-300 hover:cursor-not-allowed": disabled,
                    },
                    checkboxClassName,
                )}
                disabled={disabled}
                type="checkbox"
                onChange={() => onChange(!checked)}
                {...rest}
            />
            <label
                className={classNames(
                    "ms-2 text-sm font-medium select-none",
                    {
                        "text-gray-300 hover:cursor-not-allowed": disabled,
                        "text-inherit hover:cursor-pointer": !disabled,
                    },
                    labelClassName,
                )}
                role="button"
                onClick={() => !disabled && onChange(!checked)}
            >
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
